import React from 'react';

const Footer = () => {
    return (
        <>
            <div class="mt-2"><a class="Footer" href="https://tcouncil.dev" target="_blank" rel="noreferrer">tcouncil.dev</a></div>
        </>
    )
}

export default Footer;